//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-820:_4354,_7908,_5428,_3484,_1103,_7648,_604,_6400;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-820')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-820', "_4354,_7908,_5428,_3484,_1103,_7648,_604,_6400");
        }
      }catch (ex) {
        console.error(ex);
      }