//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-820:_2500,_812,_9228,_2258,_7756,_1628,_6116,_6364;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-820')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-820', "_2500,_812,_9228,_2258,_7756,_1628,_6116,_6364");
        }
      }catch (ex) {
        console.error(ex);
      }